// Layout Variables
@import '../../basics/layout-variables';



// Newsletter logo variable
$publication-newsletter-logo: '/design/source/images/rbl-logo.svg';

// override any variable below

// Typography Variables
$font-primary: 'Poppins', sans-serif;
$font-secondary: 'Poppins', sans-serif;


/* set base values */
$text-base-size: 16px;
$line-height-base: 22px;

// Font Size
$text-xs: 12px;
$text-sm: 14px;
$text-md: 18px;
$text-lg: 20px;
$text-xl: 22px;
$text-xxl: 24px;
$text-xxxl: 26px;


// Color Variables
$red: #CC0033;
$orange: #e5732e;
$light-blue: #4aa5d6;
$dark-blue: #0C2940;

$color-primary: #0066CC;
$color-secondary: #16417c;
$color-primary-light: $orange;
$color-primary-light-2: $light-blue;
$color-text: #2a2828;

$color-primary--50: #b6bfc6;
$color-primary--30: #b6bfc6;
$color-text--80: #556979;
$color-borders: $color-primary--30;
$color-white: #fff;

$publication-newsletter-logo: '/design/source/images/sao-logo.svg';

