.fp-main-nav {
  border-bottom: 2px solid $color-primary;
    .fp-main-nav__list-item {
      a {
        color: black;
        text-transform: uppercase;
        font-size: $text-sm;
        line-height: $line-height-sm;
        font-weight: 600;
      }

      &.active-list-item a{
        color:  $color-primary;
      }
    }
}
.fp-main-nav__list {
  @include space (margin, top, $spacer);
}


