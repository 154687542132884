.fp-footer {
  padding: 0;
}
.fp-footer__header {
  @include space(padding, all, $spacer * 4 $spacer * 8);
}

.fp-footer__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fp-footer__header figure {
  height: 40px;
}
.fp-footer__header figure a {
  display: flex;
  align-items: center;
}
.rbl-info {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 16px;
  img {
    width: 250px;
    height: auto;
  }
}
.fp-copyright {
  background-image: linear-gradient(to top, #354362, #0066cc);
}
.fp-footer {
  background-image: linear-gradient(to bottom, #0066cc, #0066cc);
}
.fp-copyright__links a {
  font-weight: 500;
}