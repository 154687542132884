.fp-footer {
  background-image: linear-gradient(to bottom, #142242, #090f1d);
}
.fp-footer__body {
  display: none;
}

//copyright
.fp-copyright {
  @include padding($spacer * 4);
  background-image: linear-gradient(to bottom, #090f1d, #090f1d);
}
.fp-copyright .fp-container__small {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fp-copyright__links a {

  @include space(margin, right, 0);
  @include padding(0 $spacer * 2);
  color: white;
  font-size: $text-sm;
  border-right: 1px solid white;


}
.fp-footer__logo img{
  width: 75px;
}
.fp-copyright__links p {
  display: none;
}