.fp-main-header__top {
  display: none;
}

.fp-main-header__middle {
  @include padding(0 $spacer * 4);
  background-color: $color-primary;
}
.fp-main-header__menu-search-wrapper > a svg path {
  outline-color: white;
  stroke: white;
}
.fp-main-header__logo {
  img {
    width: 75px;
  }
}
.fp-header-responsive__top {
  background-color: $color-primary;
}
.fp-header-responsive__top__logo img {
  @media (max-width: $tablet) {

    height: 30px;
    max-width: 190px;

  }
}
.fp-header-responsive__top {
  .fp-header-responsive__top--menu svg path {
    stroke: white;
  }
  .fp-main-header__links--login svg path {
    fill: white;
  }
}

#fp-main-header__links-wrapper-mobile {
  display: none;
}
.fp-header-responsive-wrapper {
  .rbl-info {
    margin-left: 0;
    img {
      width: 190px;
    }
  }
}
.fp-main-header__middle {
  .rbl-info {
    margin-left: 0;
  }
}